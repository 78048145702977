<template>
	<div class="relative">
		<div class="grid grid-gap-10 mb-30" :class="{ 'grid-8-3': !noUpload }">
			<a
				href="#"
				@click.prevent="$emit('download', link)"
				class="flex space-between block py-30 px-20 color-blue background-white border-light rounded-lg shadow-lg hover:background-ultra-light-grey-300"
			>
				<div class="heavy color-black">{{ document.name }}</div>

				<div>Heinta</div>
			</a>

			<template v-if="!loading && !noUpload">
				<!-- Document is present -->
				<a
					v-if="document.file"
					@click.prevent="downloadFile(document.file.uuid)"
					href="#"
					class="checkmark relative flex flex-column justify-center align-center py-20 color-blue background-blue-100 border-blue-200 rounded-lg small-text hover:background-blue-200"
				>
					PDF-fíla skrásett
				</a>

				<!-- Document is yet to be uploaded -->
				<div
					v-if="!document.file"
					href="#"
					class="relative flex justify-center align-center py-20 color-blue background-white border-light rounded-lg hover:background-ultra-light-grey-300"
				>
					Uploada skjal

					<input type="file" class="absolute top left w-100 h-100 opacity-0 cursor-pointer" @change="uploadFile" accept="application/pdf" />
				</div>
			</template>

			<!-- Remove document -->
			<a v-if="document.file && !noUpload" @click.prevent="removeFile(document.file.uuid)" class="remove-document">
				<i class="fas fa-trash-alt"></i>
			</a>

			<!-- Loader -->
			<div
				v-if="loading"
				href="#"
				class="relative flex justify-center align-center py-20 color-blue background-white border-light rounded-lg hover:background-ultra-light-grey-300"
			>
				<div class="flex justify-center">
					<i class="fas fa-spinner fa-spin fa-lg color-blue"></i>
				</div>
			</div>

			<a ref="anchor" href="#" download class="hidden"></a>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
export default {
	name: 'Documents',

	props: ['document', 'agreement', 'noUpload'],

	data() {
		return {
			loading: false,
		};
	},

	computed: {
		link() {
			const token = this.$route.query.token;

			return `${process.env.VUE_APP_TERMINAL_API_URL}/agreement-links/${token}/original-file/${this.document.uuid}`;
		},
	},

	methods: {
		getGradient() {
			const progress = this.document.progress;
			const remaining = 100 - progress;

			return `background: -webkit-linear-gradient(left, #ccedfa ${progress}%, white ${remaining}%)`;
		},

		uploadFile(e) {
			const token = this.$route.query.token;

			const formData = new FormData();

			this.loading = true;

			formData.append('file', e.target.files[0]);
			formData.append('original_file_uuid', this.document.uuid);

			axios
				.post(`/agreement-links/${token}/file`, formData)
				.then((response) => {
					this.$emit('file-uploaded', response.data);
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.loading = false;
				});
		},

		removeFile(fileUuid) {
			if (!confirm('Ert tú vís/ur?')) {
				return;
			}

			const token = this.$route.query.token;

			axios
				.delete(`/agreement-links/${token}/file/${fileUuid}`)
				.then(() => {
					this.document.file = null;
				})
				.catch((error) => {
					console.log(error);
				});
		},

		downloadFile() {
			const token = this.$route.query.token;

			axios
				.get(`/agreement-links/${token}/file/${this.document.file.uuid}`, {
					responseType: 'blob',
				})
				.then((response) => {
					const url = URL.createObjectURL(response.data);

					this.$refs.anchor.download = this.document.file.name;
					this.$refs.anchor.href = url;

					this.$nextTick(() => {
						this.$refs.anchor.click();
					});
				})
				.catch((error) => {
					console.log(error);
				});
		},
	},
};
</script>

<style lang="scss" scoped>
.remove-document {
	position: absolute;
	top: -8px;
	right: -8px;
	width: 28px;
	height: 28px;

	display: grid;
	place-items: center;

	background: #fff;

	border-radius: 50%;

	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);

	cursor: pointer;

	&:hover {
		color: var(--color-red);
		background: #f8f8f8;
	}
}
</style>
