<template>
	<div id="kyc" class="background-white w-100 mb-60 py-60 px-80 shadow-lg rounded">
		<h1 class="mb-20">Upplýsingar um fyritøkuna</h1>

		<div class="mb-30">
			<h2 class="mb-10">Søla</h2>

			<p>Um tú ikki kennir umsetningin ella miðalkeyp pr. sølu, er ynskiligt, at tú gert eina meting.</p>
		</div>

		<div class="grid grid-2 grid-gap-x-30 align-end">
			<InputGroup v-model="form.expectedSale" label="Árligur umsetningur upp á Visa og MasterCard" :required="true" />

			<InputGroup v-model="form.expectedPrTransaction" label="Miðal krónutal fyri hvørt keyp" :required="true" />

			<InputGroup
				v-model="form.fromWhere"
				label="Hvar koma teir pengarnir frá, sum Swedbankpay skal handfara tykkara vegna í hesi avtalu?"
				:required="true"
			/>

			<InputGroup
				v-model="form.customerAndProducts"
				label="Lýs tykkara vanliga kunda og tað vøru/tænastu kundin vanliga keypur frá tykkum"
				:required="true"
			/>
		</div>

		<YesNo v-model="form.abroad" label="Vit koma at nýta Swedbankpay til virksemi uttanfyri Føroyar" />

		<transition name="slide-fade">
			<div v-if="form.abroad" class="grid grid-2 grid-gap-x-20 align-end">
				<InputGroup v-model="form.abroadCountries" label="Hvat annað land selja tit til?" :required="true" />

				<InputGroup v-model="form.abroadPercentage" label="Hvussu stórur partur av tíni sølu er tað?" :required="true" />
			</div>
		</transition>

		<hr class="my-30" />

		<label class="inline-block mt-20 mb-10 cursor-pointer">
			<input type="checkbox" v-model="form.isListedCompany" />

			Virksomheden er et børsnoteret selskab (et selskab, hvis aktier kan handles på et reguleret marked i EØS).
		</label>

		<hr class="my-30" />

		<div class="mb-20">
			<h2 class="mb-10">Samtaksbygnaður</h2>

			<p>Lýsing av samtaksbygnaðinum kann viðheftast í KYC spurnarblaði fyri seg.</p>
		</div>

		<YesNo v-model="form.companyGroup" label="Fyritøkan er partur av einum samtaki" />

		<transition name="slide-fade">
			<div v-if="form.companyGroup">
				<p class="mb-30" style="font-style: italic">Vinarliga útfyll upplýsingarnar um aðrar fyritøkur í samtakinum niðanfyri.</p>

				<div v-for="i in otherCompaniesAmount" :key="i">
					<h2 class="mb-10">Fyritøka {{ i }}</h2>

					<div class="grid grid-2 grid-gap-x-20 align-end">
						<InputGroup v-model="form.companyGroups[i - 1].name" label="Virkisnavn" :required="true" />

						<InputGroup v-model="form.companyGroups[i - 1].cvr" label="V-tal" :required="true" />

						<InputGroup v-model="form.companyGroups[i - 1].address" label="Bústaður" :required="true" />

						<InputGroup v-model="form.companyGroups[i - 1].country" label="Land" :required="true" />
					</div>

					<hr v-if="i != otherCompaniesAmount" class="mb-30" />
				</div>

				<div v-if="otherCompaniesAmount < 3" class="flex justify-center">
					<div @click="otherCompaniesAmount++" class="add-company">
						<i class="fas fa-plus"></i>
					</div>
				</div>
			</div>
		</transition>

		<hr class="mb-30" />

		<div class="mb-30">
			<h2 class="mb-10">Politisk udsat person (PEP)</h2>

			<p>
				Med PEP menes en person, som har eller tidligere har haft (inden for de seneste 18 måneder) en politisk udsat post eller en høj statslig
				stilling, eller som har et nært familiemedlem, eller er en nær medarbejder til en sådan person.
			</p>
		</div>

		<p>
			Er der (1) nogen af repræsentanterne (II) reelle ejere eller (III) familiemedlemmer eller kendte medarbejdere af nogen af disse, der har eller har
			haft en af følgende roller:
		</p>

		<ul>
			<li>Statschefer, regeringschefer, ministre og viceministre eller assisterende ministre.</li>
			<li>Parlamentsmedlemmer.</li>
			<li>Højesteretsdommere, medlemmer af forfatningsdomstole og af andre højtstående retsinstanser, hvis</li>
			<li>beslutninger kun er genstand for yderligere prøvelse under ekstraordinære omstændigheder.</li>
			<li>Medlemmer af revisionsretter og af bestyrelser for centralbanker.</li>
			<li>Ambassadører, chargés d'affaires og højtstående officerer i de væbnede styrker.</li>
			<li>Medlemmer af statsejede virksomheders administrative, ledende eller kontrollerende organer.</li>
		</ul>

		<label class="inline-block mt-10 mb-30 cursor-pointer">
			<input type="checkbox" v-model="form.pep.none" />

			Einki av omanfyri
		</label>

		<transition name="slide-fade">
			<div v-if="!form.pep.none" class="grid grid-2 grid-gap-x-20 align-end">
				<InputGroup v-model="form.pep.name" label="Navn" :required="true" />

				<InputGroup v-model="form.pep.cpr" label="P-tal" :required="true" />

				<InputGroup v-model="form.pep.address" label="Bústaður" :required="true" />

				<InputGroup v-model="form.pep.function" label="Alment starv" :required="true" />

				<InputGroup v-model="form.pep.taxationCountry" label="Skattaland" :required="true" />

				<InputGroup v-model="form.pep.relation" label="Hvussu er títt samband við kundan" :required="true" />
			</div>
		</transition>
	</div>
</template>

<script>
import { required, requiredIf } from 'vuelidate/lib/validators';

import InputGroup from '@/components/InputGroup.vue';
import YesNo from '@/components/YesNo.vue';

export default {
	name: 'ECom',

	props: ['value', 'ownerSignatures'],

	components: {
		InputGroup,
		YesNo,
	},

	data() {
		return {
			otherCompaniesAmount: 1,

			form: {
				expectedSale: '',
				expectedPrTransaction: '',
				fromWhere: '',
				customerAndProducts: '',
				abroad: undefined,
				abroadCountries: '',
				abroadPercentage: '',
				isListedCompany: false,
				companyGroup: undefined,
				companyGroups: [
					{
						name: '',
						cvr: '',
						address: '',
						country: '',
					},
					{
						name: '',
						cvr: '',
						address: '',
						country: '',
					},
					{
						name: '',
						cvr: '',
						address: '',
						country: '',
					},
				],
				pep: {
					none: false,
					name: '',
					cpr: '',
					address: '',
					function: '',
					taxationCountry: '',
					relation: '',
				},
			},
		};
	},

	validations: {
		form: {
			expectedSale: {
				required,
			},

			expectedPrTransaction: {
				required,
			},

			fromWhere: {
				required,
			},

			customerAndProducts: {
				required,
			},

			abroad: {
				required,
			},

			abroadCountries: {
				required: requiredIf((form) => {
					return form.abroad;
				}),
			},

			abroadPercentage: {
				required: requiredIf((form) => {
					return form.abroad;
				}),
			},

			companyGroup: {
				required,
			},

			pep: {
				name: {
					required: requiredIf((form) => {
						return !form.none;
					}),
				},

				cpr: {
					required: requiredIf((form) => {
						return !form.none;
					}),
				},

				address: {
					required: requiredIf((form) => {
						return !form.none;
					}),
				},

				function: {
					required: requiredIf((form) => {
						return !form.none;
					}),
				},

				taxationCountry: {
					required: requiredIf((form) => {
						return !form.none;
					}),
				},

				relation: {
					required: requiredIf((form) => {
						return !form.none;
					}),
				},
			},
		},
	},

	mounted() {
		this.load(this.value);
	},

	methods: {
		load(data) {
			if (!data) {
				return;
			}

			this.form.expectedSale = data['kyc-expectedSale'];
			this.form.expectedPrTransaction = data['kyc-expectedPrTransaction'];
			this.form.fromWhere = data['kyc-fromWhere'] || 'Kort sølu';
			this.form.customerAndProducts = data['kyc-customerAndProducts'];

			this.form.abroad = data['kyc-abroad'];
			this.form.abroadCountries = data['kyc-abroadCountries'];
			this.form.abroadPercentage = data['kyc-abroadPercentage'];

			this.form.isListedCompany = data['kyc-isListedCompany'];

			this.form.companyGroup = data['kyc-companyGroup'];

			for (let i = 0; i < 3; ++i) {
				this.form.companyGroups[i].name = data[`kyc-companyGroup-${i + 1}-name`];
				this.form.companyGroups[i].cvr = data[`kyc-companyGroup-${i + 1}-cvr`];
				this.form.companyGroups[i].address = data[`kyc-companyGroup-${i + 1}-address`];
				this.form.companyGroups[i].country = data[`kyc-companyGroup-${i + 1}-country`];

				if (i == 2) {
					break;
				}

				if (
					this.form.companyGroups[i].name ||
					this.form.companyGroups[i].cvr ||
					this.form.companyGroups[i].address ||
					this.form.companyGroups[i].country
				) {
					this.otherCompaniesAmount++;
				}
			}

			this.form.pep.none = data['kyc-pep-none'];
			this.form.pep.name = data['kyc-pep-name'];
			this.form.pep.cpr = data['kyc-pep-cpr'];
			this.form.pep.address = data['kyc-pep-address'];
			this.form.pep.function = data['kyc-pep-function'];
			this.form.pep.taxationCountry = data['kyc-pep-taxationCountry'];
			this.form.pep.relation = data['kyc-pep-relation'];
		},
	},

	watch: {
		form: {
			deep: true,

			handler(value) {
				const data = {};

				data['kyc-expectedSale'] = value.expectedSale;
				data['kyc-expectedPrTransaction'] = value.expectedPrTransaction;
				data['kyc-fromWhere'] = value.fromWhere;
				data['kyc-customerAndProducts'] = value.customerAndProducts;

				if (value.abroad != undefined) {
					if (value.abroad) {
						data['kyc-abroad'] = true;
					}

					if (!value.abroad) {
						data['kyc-abroad'] = false;
					}
				}

				data['kyc-abroadCountries'] = value.abroadCountries;
				data['kyc-abroadPercentage'] = value.abroadPercentage;

				data['kyc-isListedCompany'] = value.isListedCompany;

				if (value.companyGroup != undefined) {
					if (value.companyGroup) {
						data['kyc-companyGroup'] = true;
					}

					if (!value.companyGroup) {
						data['kyc-companyGroup'] = false;
					}
				}

				let index = 1;
				for (const companyGroup of value.companyGroups) {
					data[`kyc-companyGroup-${index}-name`] = companyGroup.name;
					data[`kyc-companyGroup-${index}-cvr`] = companyGroup.cvr;
					data[`kyc-companyGroup-${index}-address`] = companyGroup.address;
					data[`kyc-companyGroup-${index}-country`] = companyGroup.country;

					index++;
				}

				data['kyc-pep-none'] = value.pep.none;
				data['kyc-pep-name'] = value.pep.name;
				data['kyc-pep-cpr'] = value.pep.cpr;
				data['kyc-pep-address'] = value.pep.address;
				data['kyc-pep-function'] = value.pep.function;
				data['kyc-pep-taxationCountry'] = value.pep.taxationCountry;
				data['kyc-pep-relation'] = value.pep.relation;

				this.$emit('input', { ...data });
				this.$emit('valid', !this.$v.form.$invalid);
			},
		},
	},
};
</script>

<style lang="scss" scoped>
.add-company {
	display: grid;
	place-items: center;

	width: 32px;
	height: 32px;

	color: white;
	background: var(--color-blue);

	border-radius: 50%;
	cursor: pointer;

	transition: 0.2s background-color ease;

	&:hover {
		background: var(--color-blue-600);
	}
}

p {
	line-height: 1.5;
}
</style>
