<template>
	<aside>
		<a v-if="fields.agreementDetails !== undefined" href="#details" class="aside-item" :class="{ success: fields.agreementDetails }" @click.prevent="scroll"
			>Avtala</a
		>
		<a v-if="fields.customer !== undefined" href="#customer" class="aside-item" :class="{ success: fields.customer }" @click.prevent="scroll"
			>Upplýsingar um kundan</a
		>
		<a v-if="fields.services !== undefined" href="#services" class="aside-item" :class="{ success: fields.services }" @click.prevent="scroll">Tænastur</a>
		<a v-if="fields.location !== undefined" href="#location" class="aside-item" :class="{ success: fields.location }" @click.prevent="scroll">Sølustað</a>
		<a v-if="fields.owners !== undefined" href="#owners" class="aside-item" :class="{ success: fields.owners }" @click.prevent="scroll">Persónar</a>
		<a v-if="fields.bankAccount !== undefined" href="#bank" class="aside-item" :class="{ success: fields.bankAccount }" @click.prevent="scroll"
			>Bankakonta</a
		>
		<a v-if="fields.ecom !== undefined" href="#ecom" class="aside-item" :class="{ success: fields.ecom }" @click.prevent="scroll">Nethandilsavtala</a>
		<a v-if="fields.swedbank !== undefined" href="#swedbank" class="aside-item" :class="{ success: fields.swedbank }" @click.prevent="scroll"
			>Swedbank avtala</a
		>
		<a v-if="fields.terminals !== undefined" href="#terminals" class="aside-item" :class="{ success: fields.terminals }" @click.prevent="scroll"
			>Terminalar</a
		>
		<a v-if="fields.kyc !== undefined" href="#kyc" class="aside-item" :class="{ success: fields.kyc }" @click.prevent="scroll">Upplýsingar um fyritøkuna</a>
	</aside>
</template>

<script>
export default {
	name: 'SideBar',

	props: ['fields'],

	methods: {
		scroll(e) {
			const target = e.target;
			const section = document.querySelector(target.hash);
			const offsetTop = section.offsetTop;
			const headerHeight = document.querySelector('header').scrollHeight || 0;

			window.scrollTo({ top: offsetTop - headerHeight - 20, behavior: 'smooth' });
		},
	},
};
</script>
