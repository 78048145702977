<template>
	<div id="bank" class="background-white w-100 mb-60 py-60 px-80 shadow-lg rounded">
		<h1 class="mb-10">Bankakonta</h1>

		<div class="mb-30">
			<p class="inline group color-grey">
				Hvør bankakonta skal knýtast til avtaluna?

				<Tooltip>Her er talan um bankakontu, sum peningurin verður fluttur inná, og kontu sum gjøld fyri avtaluna verða trekt av.</Tooltip>
			</p>
		</div>

		<template v-if="bankAccounts.length">
			<select v-model="bankAccount">
				<option value="">Vel kontu</option>

				<option v-for="(bankAccount, index) in bankAccounts" :key="`bank-account-${index}`" :value="bankAccount.id">
					{{ bankAccount.registrationNumber }} {{ bankAccount.accountNumber }}
				</option>
			</select>

			<div v-if="agreementType == 'Stutttíðarleiga'" class="mt-30">
				<InputGroup v-model="bankComment" label="Viðmerking til bankaflyting" />
			</div>

			<transition name="slide-fade">
				<label v-if="agreementType == 'KYC'" class="inline-block mt-20 mb-10 cursor-pointer">
					<input type="checkbox" v-model="verified" />

					Eg vátti at omanfyri valda bankakonta, sum er nýtt til hesa avtalu, er skrásett við omanfyri nevnda firmanavni sum kontueigari.
				</label>
			</transition>
		</template>

		<p v-if="!bankAccounts.length" class="color-grey-300 mb-20 text-center border py-15 rounded">Ongin konta er stovnað.</p>

		<AddBankOverlay
			:visible="overlay"
			:through-agreement-link="true"
			@add="
				$emit('add-account', $event);
				bankAccount = $event.id;
			"
			@close="overlay = false"
		/>

		<div class="flex justify-end">
			<a href="#" @click.prevent="overlay = true" class="btn inline-block mt-10">Legg konto afturat</a>
		</div>
	</div>
</template>

<script>
import AddBankOverlay from '../../components/AddBankOverlay.vue';
import Tooltip from '../../components/Tooltip.vue';
import InputGroup from '../../components/InputGroup.vue';

export default {
	name: 'Bank',

	props: ['value', 'comment', 'bankAccounts', 'isVerified', 'agreementType'],

	components: {
		AddBankOverlay,
		Tooltip,
		InputGroup,
	},

	data() {
		return {
			bankAccount: '',
			bankComment: '',
			overlay: false,
			verified: false,
		};
	},

	mounted() {
		if (!this.value) {
			return;
		}

		this.bankAccount = this.value.id;

		this.bankComment = this.comment;

		this.verified = this.isVerified;
	},

	watch: {
		bankAccount: function (value) {
			const bankAccount = this.bankAccounts.find((bankAccount) => bankAccount.id == Number(value));

			this.$emit('input', bankAccount);

			if (this.agreementType == 'KYC') {
				this.$emit('valid', !!bankAccount && this.verified);
			} else {
				this.$emit('valid', !!bankAccount);
			}
		},

		value: function (value) {
			if (!value) {
				return;
			}

			this.bankAccount = value.id;
		},

		verified: function (verified) {
			this.$emit('verified', verified || null);

			const bankAccount = this.bankAccounts.find((bankAccount) => bankAccount.id == Number(this.bankAccount));

			if (this.agreementType == 'KYC') {
				this.$emit('valid', !!bankAccount && verified);
			} else {
				this.$emit('valid', !!bankAccount);
			}
		},

		bankComment: function (comment) {
			this.$emit('comment', comment);
		},
	},
};
</script>

<style scoped>
select {
	width: 100%;

	padding: 16px;

	background-color: white;

	border: 1px solid #ddd;
	border-radius: 8px;
}

label {
	color: var(--color-gray-700);

	line-height: 1.3;
	font-weight: 400;
}
</style>
