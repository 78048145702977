<template>
	<div id="swedbank" class="background-white w-100 mb-60 py-60 px-80 shadow-lg rounded">
		<h1 class="mb-10">Swedbank avtala</h1>

		<!-- Loader -->
		<div v-if="loading" href="#" class="relative flex justify-center">
			<div class="flex justify-center">
				<i class="fas fa-spinner fa-spin fa-lg color-blue"></i>
			</div>
		</div>

		<div v-if="!loading">
			<!-- Salgssted -->
			<h2 class="mb-20">Sølustað</h2>

			<div class="flex align-center mb-20">
				<label class="cursor-pointer">
					<input type="checkbox" v-model="form.sameAddress" class="mr-10" />

					Set kross her um sølustað er tað sama sum lóggildugur bústaður
				</label>
			</div>

			<transition name="slide-fade">
				<div v-if="!form.sameAddress">
					<div class="mb-20" style="font-style: italic">Útfyll hesar upplýsingar fyri sølustaðið</div>

					<div class="grid grid-2 grid-gap-x-20">
						<InputGroup v-model="form.salesPlace" label="Navn" :required="true" />

						<InputGroup v-model="form.phoneNumber" label="Telefon" :required="true" />

						<InputGroup v-model="form.address" label="Bústaður" :required="true" />

						<InputGroup v-model="form.postalCodeAndCity" label="Postnummar og bygd/býur" :required="true" />

						<InputGroup v-model="form.contactPerson" label="Kontaktpersónur" :required="true" />

						<InputGroup v-model="form.contactPersonEmail" label="Teldupostur hjá kontaktpersóni" :required="true" />
					</div>

					<hr class="mb-30" />
				</div>
			</transition>

			<div class="grid grid-2 grid-gap-x-20">
				<InputGroup v-model="form.seasonalMonths" label="Um árstíðshandil, hvørjir mánaðir er opið" />

				<InputGroup v-model="form.chain" label="Um partur av handlisketu, hvørjari?" />
			</div>

			<hr class="mb-30" />

			<!-- Virksomhed/Branche -->
			<h2 class="mb-20">Vinnugrein</h2>

			<InputGroup v-model="form.description" label="Stutt lýsing av vørum ella tænastu" :required="true" />

			<div class="grid grid-2 grid-gap-x-20">
				<InputGroup v-model="form.expectedCard" label="Vænta kortsøla um ári (VISA / MasterCard)" :required="true" />

				<InputGroup v-model="form.expectedAverage" label="Væntað miðalkeyp pr. sølu" :required="true" />
			</div>

			<YesNo v-model="form.bestillingsvarer" label="Bjóða tit bíleggingarvørur?" />
			<transition name="slide-fade">
				<template v-if="form.bestillingsvarer">
					<div class="grid grid-2 grid-gap-x-20">
						<InputGroup v-model="form.bestillingsvarerAmount" label="Hvussu stórur % partur av kortsøluni er bíleggingarvørur?" :required="true" />

						<InputGroup v-model="form.bestillingsvarerDelivery" label="Hvussu long er avgreiðslutíðin á bíleggingarvørum?" :required="true" />
					</div>
				</template>
			</transition>

			<YesNo v-model="form.deposit" label="Skal rindast depositum við bílegging?" />
			<transition name="slide-fade">
				<template v-if="form.deposit">
					<div class="grid grid-2 grid-gap-x-20">
						<InputGroup v-model="form.depositAmount" label="Um ja, hvussu stórur % partur?" :required="true" />

						<InputGroup v-model="form.depositWhen" label="Nær rindar kundin restina?" :required="true" />

						<InputGroup v-model="form.depositHow" label="Hvussu rindar kundin restina?" :required="true" />
					</div>
				</template>
			</transition>
		</div>
	</div>
</template>

<script>
import InputGroup from '@/components/InputGroup.vue';
import YesNo from '@/components/YesNo.vue';
import { required, requiredIf } from 'vuelidate/lib/validators';

export default {
	name: 'Swedbank',

	props: ['value'],

	components: {
		InputGroup,
		YesNo,
	},

	data() {
		return {
			signatures: 1,
			loading: true,
			form: {
				sameAddress: false,
				salesPlace: '',
				phoneNumber: '',
				address: '',
				postalCodeAndCity: '',
				contactPerson: '',
				contactPersonEmail: '',
				seasonalMonths: '',
				chain: '',
				description: '',
				expectedCard: '',
				expectedAverage: '',
				bestillingsvarer: undefined,
				bestillingsvarerAmount: '',
				bestillingsvarerDelivery: '',
				deposit: undefined,
				depositAmount: '',
				depositWhen: '',
				depositHow: '',
			},
		};
	},

	mounted() {
		this.load(this.value);

		this.loading = false;
	},

	methods: {
		load(data) {
			if (!data) {
				return;
			}

			if (!data) {
				return;
			}

			this.form.sameAddress = data['Sæt kryds her hvis den juridiske adresse er den samme som salgsstedets'];
			this.form.salesPlace = data['Salgsstedets navn'];
			this.form.phoneNumber = data['Salgsstedets telefon'];
			this.form.address = data['Salgsstedets adresse'];
			this.form.postalCodeAndCity = data['Postnummer og By_2'];
			this.form.contactPerson = data['Kontaktperson på salgsstedet'];
			this.form.contactPersonEmail = data['Email kontaktperson'];
			this.form.seasonalMonths = data['Hvis sæsonbutik anfør periode måneder'];
			this.form.chain = data['Hvis indgår i kæde angiv hvilken'];

			this.form.description = data['Beskrivelse af vare eller service ydelse feks børnetøj'];
			this.form.expectedCard = data['Forventet omsætning VISAMC pr år kr'];
			this.form.expectedAverage = data['Forventet gennemsnitskøb kr'];

			if (data['Ja Bestillingsvarer']) {
				this.form.bestillingsvarer = true;
			}
			if (data['Nej Bestillingsvarer']) {
				this.form.bestillingsvarer = false;
			}

			this.form.bestillingsvarerAmount = data['Andel bestillingsvarer'];
			this.form.bestillingsvarerDelivery = data['Leveringstid'];

			if (data['Ja Depositum']) {
				this.form.deposit = true;
			}
			if (data['Nej Depositum']) {
				this.form.deposit = false;
			}

			this.form.depositAmount = data['Andel depositum'];
			this.form.depositWhen = data['Tid for restbetaling'];
			this.form.depositHow = data['Hvordan betales rest?'];
		},
	},

	validations: {
		form: {
			sameAddress: {
				required,
			},

			salesPlace: {
				required: requiredIf((form) => {
					return !form.sameAddress;
				}),
			},

			phoneNumber: {
				required: requiredIf((form) => {
					return !form.sameAddress;
				}),
			},

			address: {
				required: requiredIf((form) => {
					return !form.sameAddress;
				}),
			},

			postalCodeAndCity: {
				required: requiredIf((form) => {
					return !form.sameAddress;
				}),
			},

			contactPerson: {
				required: requiredIf((form) => {
					return !form.sameAddress;
				}),
			},

			contactPersonEmail: {
				required: requiredIf((form) => {
					return !form.sameAddress;
				}),
			},

			description: {
				required,
			},

			expectedCard: {
				required,
			},

			expectedAverage: {
				required,
			},

			bestillingsvarer: {
				required,
			},

			bestillingsvarerAmount: {
				required: requiredIf((form) => {
					return form.bestillingsvarer;
				}),
			},

			bestillingsvarerDelivery: {
				required: requiredIf((form) => {
					return form.bestillingsvarer;
				}),
			},

			deposit: {
				required,
			},

			depositAmount: {
				required: requiredIf((form) => {
					return form.deposit;
				}),
			},

			depositWhen: {
				required: requiredIf((form) => {
					return form.deposit;
				}),
			},

			depositHow: {
				required: requiredIf((form) => {
					return form.deposit;
				}),
			},
		},
	},

	watch: {
		form: {
			deep: true,

			handler(value) {
				const data = {};

				data['Antal dage fra betaling til levering'] = value.deliveryTime;

				if (value.website != undefined) {
					if (value.website) {
						data['Ja'] = true;
					}

					if (!value.website) {
						data['Nej'] = true;
					}
				}

				data['Sæt kryds her hvis den juridiske adresse er den samme som salgsstedets'] = value.sameAddress;
				data['Salgsstedets navn'] = value.salesPlace;
				data['Salgsstedets telefon'] = value.phoneNumber;
				data['Salgsstedets adresse'] = value.address;
				data['Postnummer og By_2'] = value.postalCodeAndCity;
				data['Kontaktperson på salgsstedet'] = value.contactPerson;
				data['Email kontaktperson'] = value.contactPersonEmail;
				data['Hvis sæsonbutik anfør periode måneder'] = value.seasonalMonths;
				data['Hvis indgår i kæde angiv hvilken'] = value.chain;

				data['Beskrivelse af vare eller service ydelse feks børnetøj'] = value.description;
				data['Forventet omsætning VISAMC pr år kr'] = value.expectedCard;
				data['Forventet gennemsnitskøb kr'] = value.expectedAverage;

				if (value.bestillingsvarer != undefined) {
					if (value.bestillingsvarer) {
						data['Ja Bestillingsvarer'] = true;
						data['Nej Bestillingsvarer'] = null;
					}

					if (!value.bestillingsvarer) {
						data['Ja Bestillingsvarer'] = null;
						data['Nej Bestillingsvarer'] = true;
					}
				}

				data['Andel bestillingsvarer'] = value.bestillingsvarerAmount;

				data['Leveringstid'] = value.bestillingsvarerDelivery;

				if (value.deposit != undefined) {
					if (value.deposit) {
						data['Ja Depositum'] = true;
						data['Nej Depositum'] = null;
					}
					if (!value.deposit) {
						data['Ja Depositum'] = null;
						data['Nej Depositum'] = true;
					}
				}

				data['Andel depositum'] = value.depositAmount;
				data['Tid for restbetaling'] = value.depositWhen;
				data['Hvordan betales rest?'] = value.depositHow;

				this.$emit('input', { ...data });
				this.$emit('valid', !this.$v.form.$invalid);
			},
		},
	},
};
</script>
