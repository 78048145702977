<template>
	<div id="customer" class="background-white w-100 mb-60 py-60 px-80 shadow-lg rounded">
		<h1 class="mb-30">Upplýsingar um kundan</h1>

		<div class="flex flex-wrap mb-20">
			<div class="flex mb-10">
				<input :disabled="disabled" type="radio" v-model="companyType" value="Sp/f" id="spf" />

				<label class="pl-10 mr-20 cursor-pointer" for="spf">Sp/f</label>
			</div>

			<div class="flex mb-10">
				<input :disabled="disabled" type="radio" v-model="companyType" value="P/f" id="pf" />

				<label class="pl-10 mr-20 cursor-pointer" for="pf">P/f</label>
			</div>

			<div class="flex mb-10">
				<input :disabled="disabled" type="radio" v-model="companyType" value="fa." id="fa" />

				<label class="pl-10 mr-20 cursor-pointer" for="fa">fa.</label>
			</div>

			<div class="flex mb-10">
				<input :disabled="disabled" type="radio" v-model="companyType" value="ÍVF" id="ivf" />

				<label class="pl-10 mr-20 cursor-pointer" for="ivf">ÍVF</label>
			</div>

			<div class="flex mb-10">
				<input :disabled="disabled" type="radio" v-model="companyType" value="Íognarfelag" id="iogn" />

				<label class="pl-10 mr-20 cursor-pointer" for="iogn">Íognarfelag</label>
			</div>

			<div class="flex mb-10">
				<input :disabled="disabled" type="radio" v-model="companyType" value="Privat" id="private" />

				<label class="pl-10 mr-20 cursor-pointer" for="private">Privat</label>
			</div>

			<div class="flex mb-10">
				<input :disabled="disabled" type="radio" v-model="companyType" value="Annað" id="other" />

				<label class="pl-10 mr-20 cursor-pointer" for="other">Annað</label>
			</div>
		</div>

		<div class="grid grid-2 grid-gap-20 align-end">
			<div v-if="companyType == 'Annað'" class="mb-30">
				<label class="block mb-10">Virkisslag <span class="color-red">*</span></label>

				<div class="search-input relative">
					<input ref="companyTypeInput" type="text" v-model="form.companyType" />
				</div>
			</div>
		</div>

		<div class="grid grid-2 grid-gap-20 align-end">
			<InputGroup
				v-model="form.name"
				:label="companyType == 'Privat' && agreementType == 'Stutttíðarleiga' ? 'Navn' : `Lóggildugt navn`"
				:sublabel="companyType == 'Privat' && agreementType == 'Stutttíðarleiga' ? '' : 'Lóggildugt navn á fyritøku'"
				:required="true"
			/>

			<InputGroup
				v-if="!(companyType == 'Privat' && agreementType == 'Stutttíðarleiga')"
				v-model="form.otherName"
				label="Hjánavn"
				sublabel="Um tit nýta annað hjánavn í dagligari talu, hvat?"
			/>
		</div>

		<InputGroup v-if="!(companyType == 'Privat' && agreementType == 'Stutttíðarleiga')" v-model="form.vtal" label="V-tal" :required="true" />

		<div class="grid grid-2 grid-gap-20">
			<InputGroup v-model="form.address" label="Bústaður" :required="true" />

			<InputGroup v-model="form.taxationCountry" label="Skattskyldugt land" :required="true" />
		</div>

		<div class="grid grid-2 grid-gap-20">
			<InputGroup v-model="form.zip" label="Postnummar" :required="true" />

			<InputGroup v-model="form.city" label="Býur/bygd" :required="true" />
		</div>

		<div class="grid grid-2 grid-gap-20">
			<InputGroup v-model="form.phoneNumber" label="Telefon" :required="true" />

			<InputGroup v-model="form.email" label="Teldupostur" :required="true" />
		</div>

		<div v-if="agreementType == 'KYC'" class="grid grid-2 grid-gap-20">
			<InputGroup v-model="form.branch" label="Vinnugrein" :required="true" />

			<InputGroup v-model="form.swedbankPayReason" label="Hvat skal Swedbank Pay brúkast til?" :required="true" />
		</div>

		<!-- Fráboðanir -->
		<div v-if="!['Stutttíðarleiga', 'KYC'].includes(agreementType)">
			<hr class="mb-30" />

			<div class="add">
				<div class="flex space-between align-center mb-20">
					<div>
						<h2 class="mb-10">Fráboðanir</h2>

						<p class="group relative color-grey">
							Terminal.fo sendir felags smsboð út, um brek eru staðfest sum ávirka tína loysn.

							<Tooltip>
								Terminal.fo bjóðar øllum sínum kundum at tekna upp til trý telefonnummur til hesa tænastu.
								<br />
								Vit senda einans til føroysk telefonnummur og má tú einans skriva tíni 6 tøl í telefonnummarinum.
							</Tooltip>
						</p>
					</div>
				</div>

				<div>
					<div v-for="(phoneNumber, index) in form.mobileNumbers" class="" :key="`phoneNumber-${index}`">
						<input
							v-model="form.mobileNumbers[index]"
							ref="phoneNumberInput"
							type="text"
							@blur="
								() => {
									$v.form.mobileNumbers.$touch();

									if (index == 0) {
										return;
									}

									if (!form.mobileNumbers[index]) {
										form.mobileNumbers.splice(index, 1);
									}
								}
							"
							class="mb-10"
						/>
					</div>

					<div class="small-text color-red mb-10 px-5" v-if="hasmobileNumbers && $v.form.mobileNumbers.$invalid && $v.form.mobileNumbers.$dirty">
						Vinarliga einans skriva gildig telefonnummur
					</div>
				</div>

				<div
					class="plus flex align-center justify-center py-20 px-30 mb-30 color-blue border-light rounded-lg hover:background-ultra-light-grey-300 cursor-pointer"
					v-show="form.mobileNumbers.length < 3"
					@click="
						() => {
							if (form.mobileNumbers[form.mobileNumbers.length - 1] && form.mobileNumbers.length < 3) {
								form.mobileNumbers.push('');

								$nextTick(() => {
									this.$refs.phoneNumberInput[form.mobileNumbers.length - 1].focus();
								});
							}
						}
					"
				>
					Legg afturat
				</div>
			</div>

			<div v-if="!['KYC'].includes(agreementType)">
				<hr class="mb-30" />

				<!-- TCS -->
				<h2 class="mb-10">TCS</h2>

				<p class="group color-grey mb-20">
					Vinarliga útfyll upplýsingarnar fyri tann av tykkum, ið skal hava atgongd til TCS hjá Verifone.

					<span class="color-grey">
						<Tooltip>
							<p>
								TCS er ein online platformur, har brúkarin fær atgongd til at síggja allar transaktiónir sum eru farnar ígjøgnum Verifone
								gjaldsterminalin. TCS brúkarin er oftast onkur í bókhaldinum ella eigarin sjálvur.
							</p>

							<br />

							<p>Tit fáa ein teldupost sendandi beinleiðis frá Verifone við upplýsingum til innritan og vegleiðing.</p>
						</Tooltip>
					</span>
				</p>

				<div class="grid grid-2 grid-gap-20">
					<InputGroup v-model="form.tcs_username" label="Brúkaranavn" :disabled="disabled" />

					<InputGroup v-model="form.tcs_email" label="Teldubústaður" :disabled="disabled" />

					<InputGroup v-model="form.tcs_phone" label="Fartelefon" :disabled="disabled" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { required, minLength, email, between, numeric, helpers, requiredIf } from 'vuelidate/lib/validators';

import InputGroup from '@/components/InputGroup.vue';
import Tooltip from '@/components/Tooltip.vue';

export default {
	name: 'CustomerClient',

	props: {
		value: Object,
		agreementType: String,
		disabled: {
			type: Boolean,
			default: false,
		},
	},

	components: {
		InputGroup,
		Tooltip,
	},

	data() {
		return {
			form: {
				id: null,
				companyType: 'Annað',
				name: '',
				otherName: '',
				vtal: '',
				taxationCountry: '',
				address: this.value ? this.value.address : '',
				zip: '',
				city: '',
				phoneNumber: '',
				email: '',
				mobileNumbers: [''],
				branch: '',
				swedbankPayReason: '',
				tcs_username: '',
				tcs_email: '',
				tcs_phone: '',
			},

			companyType: '',
		};
	},

	mounted() {
		this.load(this.value);
	},

	validations: {
		form: {
			companyType: {
				required,
			},

			name: {
				required,
			},

			vtal: {
				required: requiredIf(function () {
					return !(this.companyType == 'Privat' && this.agreementType == 'Stutttíðarleiga');
				}),
			},

			taxationCountry: {
				required,
			},

			address: {
				required,
			},

			zip: {
				required,
				numeric,
				between: between(100, 970),
			},

			city: {
				required,
			},

			phoneNumber: {
				required,
			},

			email: {
				required,
				email,
			},

			branch: {
				required: requiredIf(function () {
					return this.agreementType == 'KYC';
				}),
			},
			swedbankPayReason: {
				required: requiredIf(function () {
					return this.agreementType == 'KYC';
				}),
			},

			mobileNumbers: {
				$each: {
					numbercheck: helpers.regex('number', /^[0-9]{6}$/),
				},
			},
		},
	},

	computed: {
		hasmobileNumbers() {
			for (const phoneNumber of this.form.mobileNumbers) {
				if (phoneNumber) {
					return true;
				}
			}

			return false;
		},
	},

	watch: {
		'$v.form.$invalid': function () {
			this.$emit('valid', this.$v.$invalid);
		},

		value: {
			handler(customer) {
				this.load(customer);
			},
		},

		companyType: function (companyType) {
			if (companyType == 'Annað') {
				if (['Sp/f', 'P/f', 'fa.', 'ÍVF', 'Íognarfelag'].includes(this.form.companyType)) {
					this.form.companyType = '';
				}

				this.$nextTick(() => {
					this.$refs.companyTypeInput.focus();
				});

				return;
			}

			this.form.companyType = companyType;
		},

		form: {
			deep: true,

			handler(value) {
				this.$emit('valid', !this.$v.form.$invalid);

				this.$emit('input', value);
			},
		},
	},

	methods: {
		touchInput(element) {
			this.$v.form[element].$touch();
		},

		load(customer) {
			this.form.id = customer.id;
			this.form.name = customer.name;
			this.form.otherName = customer.otherName;
			this.form.taxationCountry = customer.taxationCountry;
			this.form.address = customer.address;
			this.form.zip = customer.zip;
			this.form.city = customer.city;
			this.form.email = customer.email;
			this.form.phoneNumber = customer.phoneNumber;
			this.form.mobileNumbers = customer.mobileNumbers;
			this.form.vtal = customer.vtal;
			this.form.branch = customer.branch;
			this.form.swedbankPayReason = customer.swedbankPayReason || 'Móttaka gjaldskort';
			this.form.tcs_username = customer.tcs_username;
			this.form.tcs_email = customer.tcs_email;
			this.form.tcs_phone = customer.tcs_phone;

			this.form.companyType = customer.companyType;

			this.companyType = ['Sp/f', 'P/f', 'fa.', 'ÍVF', 'Íognarfelag'].includes(customer.companyType) ? customer.companyType : 'Annað';
		},
	},
};
</script>

<style lang="scss" scoped>
.grid-gap-20 {
	row-gap: 0;
}

.add {
	.btn-round.disabled {
		opacity: 0.5;
		pointer-events: none;
	}
	&:hover {
		.btn-round.disabled {
			opacity: 0.5;
		}
	}
}

@media (max-width: 600px) {
	.grid-2 {
		grid-template-columns: 1fr;
	}
}
</style>
